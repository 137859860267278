
import { Component, Vue, Watch } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { ref } from 'vue';

@Component
export default class RenewPwdPage extends Vue {
  name: "RenewPwdPage";

  uid: string | undefined;
  isUuidValid = false;
  userId= "";
  password = "";
  confirmPassword = "";

  userEmail= "";

  pwd=false;
  pwdEqual=false;


  show1 = ref(false);
  show2 = ref(false);

  min = 8;
  max = 12;

  data(): any {
    return {
      isLoading:true,
      formLoading:false,
      error_content:"",
      error_display:false,
      arePwdIdentiq : true
    }
  }

  reg_success = false;

  created(){
    this.uid= this.$route.params["uid"] // uuid de la page
    this.isLoading = true;

    this.$store.dispatch("checkUuid", {
      uid: this.uid,
      next: (response) => {
        this.userId = response.user_id;
        this.userEmail = response.user_email;
        console.log(response.user_email);
        if ( this.userId ) {
          this.isUuidValid = true;
        //   this.userId = response;
          this.isLoading = false;
        }
        },
      errorNext: (error) => {
        this.isUuidValid = false;
        this.isLoading = false;
        }
    })
  }

  hasLower(text: string): boolean {
    const regex = /[a-z]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasUppercase(text: string): boolean {
    const regex = /[A-Z]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasSymbol(text: string): boolean {
    const regex = /[!@#$%^&*(),.?":{}|<>]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
    //from le back [!@#$%^&*(),.?":{}|<>]
  }

  hasNumber(text: string): boolean {
    const regex = /\d+/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasSpace(text: string): boolean {
    const regex = /[\s]+/;
    this.pwd=this.pwd && !regex.test(text);
    return regex.test(text);
  }

  hasPWD(text: string): boolean {
    if(text.length>0){
      this.pwd=true
      return(true)
    }
    this.pwd=false
    return false;
  }
  rules = {
    required: value => !! this.hasPWD(value) || 'Le mot de passe est requis',
    min: value => value.length >= this.min ||  `Doit contenir au moins ${this.min} caractères`,
    max: v => v.length <= this.max ||  `Doit contenir moins de ${this.max} caractères`,
    pwdLower: value => !! this.hasLower(value) || 'Doit contenir une lettre minuscule',
    pwdUpper: value => !! this.hasUppercase(value) || 'Doit contenir une lettre majuscule',
    pwdSymbol: v => !! this.hasSymbol(v) || 'Doit contenir un caractère spécial : !@#$%^&*(),.?":{}|<> ',
    pwdSpace: v => ! this.hasSpace(v) || "Ne peut pas contenir d'espace",
    pwdNumber: v => !! this.hasNumber(v) || 'Doit contenir au moins un chiffre',
  }

  // confrules = {
  //   egalite: v => this.pwdEgal(v) || 'Les mots de passe ne correspondent pas',
  // }


  disableForm = false;

  sendRenewPwd(): any{
    this.formLoading=true
    if (this.arePwdIdentiq && this.password) {
      this.$store.dispatch("RenewPwd", {
        user_id: this.userId,
        token: this.uid,
        new_pwd: this.password,
        next: (response) => {

          this.$router.push({ name: 'pwdvalidate' })
        },
        errorNext: () => {
          console.log("Une erreur est survenue, veuillez réessayer ultérieurement ou contactez un administrateur.");
          this.error_display=true
          this.error_content="Une erreur est survenue, veuillez réessayer ultérieurement ou contactez un administrateur."
          this.formLoading=false;
        }
      })
    }
  }

  @Watch("confirmPassword")
  @Watch("password")
  checkConfPwdInput(): void {
    if(this.confirmPassword!=""){
      if (this.confirmPassword == this.password) {
        this.arePwdIdentiq = true;
      }else{
        this.arePwdIdentiq = false;
      }
    }
  }


  get isSubmitDisable(): any {
    return (
      !this.pwd ||
      !this.arePwdIdentiq
    );
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

}
